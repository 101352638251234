import axios from 'axios'

import config from '../../config'

import { actionTypes } from './constants'
import { buildS3Url, getRawS3Url } from './selectors'

import { selectors as userSelectors } from '../user'
import { selectors as routeSelectors } from '../route'

export const listBucketItems = (auth) => {
  return async (dispatch, getState) => {
    const token = (auth && auth.token) || userSelectors.getToken(getState())
    dispatch(requestingBucketItems(true))
    dispatch(updateS3Url(buildS3Url(getState())))
    try {
      const data = { prefix: routeSelectors.getDropZonePrefix() }
      const { data: result } = await axios({
        method: 'POST',
        url: `${config.serverUrl}/bucket/list`,
        data,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      dispatch(updateBucketItems(result))
    } catch (err) {
      dispatch(listBucketItemsFailed(err.message))
    }
    dispatch(requestingBucketItems(false))
  }
}

export const listBucketItemsFailed = () => ({
  type: actionTypes.LIST_BUCKET_FAILED
})

export const requestingBucketItems = (requesting) => ({
  type: actionTypes.REQUESTING_ITEMS,
  payload: requesting
})

export const updateBucketItems = (items) => ({
  type: actionTypes.UPDATE_BUCKET_ITEMS,
  payload: items
})

export const updateS3Url = (url) => ({
  type: actionTypes.UPDATE_S3URL,
  payload: url
})

export const addingFolder = (isAdding) => ({
  type: actionTypes.ADDING_FOLDER,
  payload: isAdding
})

export const addingFolderError = () => ({
  type: actionTypes.ADDING_FOLDER_ERROR
})

export const addFolder = (title) => {
  return async (dispatch, getState) => {
    dispatch(addingFolder(true))
    dispatch(addingFolderError(false))
    const token = userSelectors.getToken(getState())
    const prefix = getRawS3Url(getState())
    try {
      await axios({
        method: 'POST',
        url: `${config.serverUrl}/bucket/add`,
        data: { prefix, title },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      dispatch(listBucketItems())
      dispatch(setNewFolderModalOpen(false))
    } catch (err) {
      dispatch(addingFolderError(true))
    }
    dispatch(addingFolder(false))
  }
}

export const setNewFolderModalOpen = (open) => ({
  type: actionTypes.NEW_FOLDER_MODAL_OPEN,
  payload: open
})

export const deleteItem = (item) => {
  return async (dispatch, getState) => {
    const token = userSelectors.getToken(getState())
    try {
      await axios({
        method: 'POST',
        url: `${config.serverUrl}/bucket/delete`,
        data: { prefix: item.key, item },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      dispatch(listBucketItems())
    } catch (err) {
      // todo dispatch generic message error
    }
  }
}