import { actionTypes } from './constants'

const initialState = {
  requestingBucket: true,
  addingFolderError: false,
  isAddingFolder: false
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.LIST_BUCKET_FAILED:
      return { ...state, listBucketFailed: payload }
    case actionTypes.UPDATE_BUCKET_ITEMS:
      return { ...state, items: payload }
    case actionTypes.REQUESTING_ITEMS:
      return { ...state, requestingBucket: payload }
    case actionTypes.UPDATE_S3URL:
      return { ...state, s3Url: payload }
    case actionTypes.ADDING_FOLDER:
      return { ...state, isAddingFolder: payload }
    case actionTypes.ADDING_FOLDER_ERROR:
      return { ...state, addingFolderError: payload }
    case actionTypes.NEW_FOLDER_MODAL_OPEN:
      return { ...state, isNewFolderModalOpen: payload }
    default:
      return state
  }
} 

export default reducer