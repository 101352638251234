import React from "react"
import { connect } from "react-redux"
import { Redirect, Route } from "react-router"

import { selectors as userSelectors } from '../domains/user'
import { constants as routeConstants } from '../domains/route'

const AuthRoute = props => {
  const { isAuthUser, path } = props
  const { ROUTES } = routeConstants
  const isHome = path === ROUTES.HOME
  if ((isHome || path.toLowerCase().indexOf(ROUTES.DROPZONE) !== -1) && !isAuthUser) {
    return <Redirect to={ROUTES.LOGIN} />
  }
  if ((isHome || [ROUTES.LOGIN].includes(path.toLowerCase())) && isAuthUser) {
    return <Redirect to={ROUTES.DROPZONE} />
  }
  return <Route {...props} />
}

const mapStateToProps = (state) => ({
  isAuthUser: userSelectors.getToken(state)
})

export default connect(mapStateToProps)(AuthRoute)
