import "./Login.css";

import React, { useState } from "react";
import { actionCreators as userActions, selectors as userSelectors } from "../domains/user";

import { connect } from "react-redux";

export default connect(
  (state) => ({
    isInvalidPassword: userSelectors.getIsInvalidPassword(state),
  }),
  {
    login: (user) => userActions.login(user),
  }
)((props) => {
  const { isInvalidPassword } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isRequestingLogin, setIsRequestingLogin] = useState(false);

  const submitForm = () => {
    setIsRequestingLogin(true);
    props.login({ email, password });
  };

  const validateForm = () => email.length > 0 && password.length > 0;

  return (
    <div className="login">
      <div className="back-logo" style={{ backgroundColor: "#001C38" }}>
        <img className="logo" src="https://www.g6internet.com.br/wp-content/uploads/2020/09/g6-internet-fibra-logo-branco.png" />
      </div>

      <div className="back-form">
        <div className="saudation">
          <h1 className="saudation-text-line1">Bem-vindo novamente,</h1>
          <h1 className="saudation-text-line2">Acesse sua conta ISP Drive</h1>
        </div>
        <form className="form">
          <div className="user">Usuário</div>
          <input type="text" className="user-input" placeholder="Digite seu usuário" value={email} onChange={(e) => setEmail(e.target.value)} />

          <div className="password">Senha</div>
          <input
            type="password"
            className="password-input"
            placeholder="Digite sua senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="messages-section">{isInvalidPassword && <div className="required-fields">Usuário ou senha inválidos</div>}</div>
          <button
            type="button"
            onClick={submitForm}
            className="login-button"
            disabled={!validateForm()}
            style={{ backgroundColor: "#FD581F" }}
          >
            <div className="button-text">{(!isInvalidPassword && isRequestingLogin && "Aguarde...") || "Entrar"}</div>
          </button>
        </form>
      </div>
    </div>
  );
});
