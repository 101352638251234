import Modal from 'react-modal'

import './DialogConfirm.css'

const DialogConfirm = (props) => {
  const customStyles = {
    content : {
      top: '30%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'rgb(250 250 250)'
    }
  }
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onCancel}
      style={customStyles}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
    >
      <div className="dialog-confirm">
        <div className="title">{props.title}</div>
        <div className="message">{props.message}</div>
        <div className="actions">
          <button onClick={props.onCancel} className="cancel">Cancelar</button>
          <button onClick={props.onConfirm} className="confirm">Sim</button>
        </div>
      </div>
    </Modal>
  )
}

export default DialogConfirm
