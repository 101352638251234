
import Modal from 'react-modal';
import { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";

import { ROUTES } from '../domains/route/constants'

import { 
  actionCreators as bucketActions,
  selectors as bucketSelectors
} from '../domains/bucket'

import { 
  selectors as uiSelectors
} from '../domains/ui'

import './BreadCrumbs.css'

const BreadCrumbs = (props) => {
  const { 
    isAddingFolder,
    addingFolderError,
    isNewFolderModalOpen,
    setModalOpen } = props

  const [currentTitle, setCurrentTitle] = useState('')
  const [requiredTitle, setRequiredTitle] = useState(false)
  const [invalidTItle, setInvalidTitle] = useState(false)
  const [titleTooLong, setTitleTooLong] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  
  const customStyles = {
    content : {
      top: '30%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'rgb(250 250 250)'
    }
  }

  const openModal = () => {
    setRequiredTitle(false)
    setTitleTooLong(false)
    setInvalidTitle(false)
    setModalOpen(true)
  }

  const closeModal = () =>
    setModalOpen(false)

  const buildCrumbs = () => {
    const { crumbs } = props
    return crumbs.map((crumb, i) =>
      <div key={i} className="crumb">
        {i > 0 ? <span className="crumb-sep">&gt;</span> : null}
        <Link to={`${ROUTES.DROPZONE}${crumb.path}`}>{crumb.name}</Link>
      </div>
    )
  }

  const titleChangeHandler = (e) => {
    const allowed = /^[a-z-A-Z-_\s\d]{1,50}$/

    setRequiredTitle(false)
    setTitleTooLong(false)
    setInvalidTitle(false)
    setCanSubmit(false)

    const value = e.target.value
    setCurrentTitle(value)

    if (!value) return setRequiredTitle(true)
    if (!/^.{1,50}$/.test(value)) return setTitleTooLong(true)
    if (!allowed.test(value)) return setInvalidTitle(true)

    setCanSubmit(true)
  }
  
  const addFolderHandler = () => {
    const { addFolder } = props
    setCanSubmit(false)
    addFolder(currentTitle.trim())
    setCurrentTitle('')
  }

  return (
    <Fragment>
      <div className="breadcrumbs">
        {buildCrumbs()}
        <div onClick={openModal} className="new-item">
          <div className="plus-signal">+</div>
          <FontAwesomeIcon icon={faFolder} />
        </div>
      </div>
      <Modal
        isOpen={isNewFolderModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <div className="new-item-modal">
          <div className="title">Nova Pasta</div>
          <div className="folder-name">
            <input onChange={titleChangeHandler} value={currentTitle} type="text" />
          </div>
          <div className="message-error">
            {addingFolderError && (<div>Um erro ocorreu, por favor tente novamente</div>)}
            {requiredTitle && (<div>Nome da pasta obrigatório</div>)}
            {invalidTItle && (<div>Apenas letras e números são permitidos</div>)}
            {titleTooLong && (<div>O nome não pode ter mais do que 50 caracteres</div>)}
          </div>
          <div className="actions">
            {!isAddingFolder && <button onClick={closeModal} className="btn-cancel">Cancelar</button>}
            <button disabled={!canSubmit} onClick={addFolderHandler} className="btn-create">
              {(isAddingFolder && ('Aguarde')) || ('Criar')}
            </button>
          </div>
        </div>
      </Modal>
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  addingFolderError: bucketSelectors.getAddingFolderError(state),
  isAddingFolder: bucketSelectors.isAddingFolder(state),
  isNewFolderModalOpen: bucketSelectors.isNewFolderModalOpen(state),
  crumbs: uiSelectors.getBreadCrumbs(state)
})
const mapDispatchToProps = (dispatch) => ({
  addFolder: (title) => dispatch(bucketActions.addFolder(title)),
  setModalOpen: (open) => dispatch(bucketActions.setNewFolderModalOpen(open))
})

export default connect(mapStateToProps, mapDispatchToProps)(BreadCrumbs)
