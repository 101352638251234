import "./ProviderHeader.css";

import { actionCreators as userActions, selectors as userSelectors } from "../domains/user";

import DialogConfirm from "./DialogConfirm";
import { connect } from "react-redux";
import { useState } from "react";

const ProviderHeader = (props) => {
  const [confirmLogout, setConfirmLogout] = useState(false);

  const onConfirm = () => {
    setConfirmLogout(false);
    props.logout();
  };
  const onCancel = () => {
    setConfirmLogout(false);
  };
  const requestLogout = () => {
    setConfirmLogout(true);
  };

  const { providerInfo } = props;
  const { providerId, email } = props.userInfo;

  return (
    <div className="provider-header" style={{ backgroundColor: "#001C38" }}>
      <div className="provider-logo">
        <img src="https://www.g6internet.com.br/wp-content/uploads/2020/09/g6-internet-fibra-logo-branco.png" alt="G6 Internet" />
      </div>
      <div className="provider-name">
        G6 Internet
        <div className="user-info">Olá, {email}</div>
      </div>

      <div onClick={requestLogout} className="logout">
        <span className="material-icons">logout</span>
      </div>
      <DialogConfirm isOpen={confirmLogout} title="Logout" message="Deseja realmente sair?" onConfirm={onConfirm} onCancel={onCancel} />
    </div>
  );
};

export default connect(
  (state) => ({
    userInfo: userSelectors.getUserInfo(state),
    providerInfo: userSelectors.getProviderInfo(state),
  }),
  (dispatch) => ({
    logout: () => dispatch(userActions.logout()),
  })
)(ProviderHeader);
