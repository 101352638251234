import { combineReducers } from 'redux'

import { DOMAIN as userDomain } from './domains/user/constants'
import { DOMAIN as uiDomain } from './domains/ui/constants'
import { DOMAIN as bucketDomain } from './domains/bucket/constants'

import userReducer from './domains/user/reducer'
import uiReducer from './domains/ui/reducer'
import bucketReducer from './domains/bucket/reducer'

export default combineReducers({
  [uiDomain]: uiReducer,
  [bucketDomain]: bucketReducer,
  [userDomain]: userReducer
})