
import axios from 'axios'

import config from '../../config'
import { actionTypes } from './constants'
import { constants as routeConstants } from '../route'
import { getToken } from './selectors'

export const login = user => {
  return async (dispatch) => {
    dispatch(invalidPassword(false))
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.serverUrl}/login`,
        data: user
      })
      dispatch(setAuth({ ...response.data }))
    } catch (err) {
      dispatch(invalidPassword(true))
    }
  }
}

export const invalidPassword = (invalid) => ({
  type: actionTypes.INVALID_PASSWORD,
  payload: invalid
})

export const setAuth = (auth) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_AUTH,
      payload: auth
    })
  }
}

export const logout = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.LOGOUT })
    window.location.href = routeConstants.ROUTES.LOGIN
  }
}

export const getUserFolderSize = () => {
  return async (dispatch, getState) => {
    const token = getToken(getState())
    const { data } = await axios({
      method: 'GET',
      url: `${config.serverUrl}/bucket/size`,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    dispatch(setUserFolderSize(data))
  }
}

export const setUserFolderSize = ({ size, limit, usage }) => ({
  type: actionTypes.SET_USER_FOLDER_SIZE,
  payload: { size, limit, usage }
})
