import { actionTypes, AUTH_KEY } from './constants'
import jwtDecode from 'jwt-decode'

const identity = localStorage.getItem(AUTH_KEY)

const initialState = {
  isAuthUser: !!identity,
  token: identity,
  userInfo: (identity && jwtDecode(identity)) || {},
  isInvalidPassword: false
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_AUTH:
      const token = payload.token
      localStorage.setItem(AUTH_KEY, token)
      const userInfo = jwtDecode(token)
      return { 
        ...state,
        isAuthUser: !!payload.token,
        token: payload.token,
        userInfo
      }
    case actionTypes.INVALID_PASSWORD:
      return { ...state, isInvalidPassword: payload }
    case actionTypes.LOGOUT:
      localStorage.removeItem(AUTH_KEY)
      return { ...state, ...initialState }
    case actionTypes.SET_USER_FOLDER_SIZE:
      const { size, limit, usage } = payload
      return { 
        ...state, size,  limit, usage
      }
    default:
      return state
  }
} 

export default reducer