import { getDropZonePrefix } from './selectors'
import { selectors as userSelectors} from '../user'
import { actionCreators as bucketActions } from '../bucket'

export const reactToLocation = () => {
  return (dispatch, getState) => {
    const authorization = userSelectors.getToken(getState())
    if (!authorization) return
    const dropZoneLocation = getDropZonePrefix()
    if (dropZoneLocation) {
      dispatch(bucketActions.listBucketItems())
    }
  }
}
