export const DOMAIN = 'bucket'

export const actionTypes = {
  LIST_BUCKET_FAILED: 'LIST_BUCKET_FAILED',
  UPDATE_BUCKET_ITEMS: 'UPDATE_BUCKET_ITEMS',
  REQUESTING_ITEMS: 'REQUESTING_ITEMS',
  UPDATE_S3URL: 'UPDATE_S3URL',
  ADDING_FOLDER: 'ADDING_FOLDER',
  ADDING_FOLDER_ERROR: 'ADDING_FOLDER_ERROR',
  NEW_FOLDER_MODAL_OPEN: 'NEW_FOLDER_MODAL_OPEN'
}