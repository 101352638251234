import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import Modal from 'react-modal';

import DropzoneUploader from "../components/DropzoneUploader";
import ProviderHeader from "../components/ProviderHeader";
import BreadCrumbs from "../components/BreadCrumbs";
import Bucket from "../components/Bucket";

import {
  selectors as bucketSelectors,
  actionCreators as bucketActions,
} from "../domains/bucket";

import {
  selectors as userSelectors,
} from "../domains/user";

import "./ISPDropZone.css";

const ISPDropZone = (props) => {
  const [limitExceeded, setLimitExceeded] = useState(false)

  const beforeSend = ({ files }) => {
    const { userFolderInfo } = props
    const incomingSize = files.reduce((acc, f) => f.size+acc,0)/1024**3
    const free = userFolderInfo.limit - userFolderInfo.size
    const invalidSize = incomingSize > free
    setLimitExceeded(invalidSize)
    // beforeSend tells to the component whether
    // it can (return true) upload or not (return false)
    return !invalidSize
  }

  const customStyles = {
    content : {
      top: '30%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'rgb(250 250 250)'
    }
  }

  const {
    listBucket,
    bucketItems,
    isRequestingBucket,
    s3Url,
    uploadOptions,
    userFolderInfo,
  } = props;

  return (
    <Fragment>
      <ProviderHeader />
      <div className="header-info">
        <BreadCrumbs />
        <div className="user-folder-size">{userFolderInfo.usage}</div>
      </div>
      {!isRequestingBucket && <Bucket items={bucketItems} />}
      {isRequestingBucket && (
        <div className="loading-bucket">Carregando seus items</div>
      )}

      <div className="dropzone-section">
        <DropzoneUploader
          passChildrenProps={false}
          imageComponent={null}
          onFinish={listBucket}
          s3Url={s3Url}
          maxSize={2e+8}
          upload={uploadOptions}
          beforeSend={beforeSend}
        >
          <div className="drop-here">
            <div className="msg">
              Clique aqui ou arraste arquivos<div className="msg-limit">(tamanho máximo de 200MB)</div>
            </div>
          </div>
        </DropzoneUploader>
      </div>
      <Modal
        isOpen={limitExceeded}
        onRequestClose={() => setLimitExceeded(false)}
        style={customStyles}
        ariaHideApp={false}
      >
        <div className="upload-limit-exceeded">
          <span className="material-icons">info</span>Upload excede limite disponível
        </div>
      </Modal>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  bucketItems: bucketSelectors.getBucketItems(state),
  isRequestingBucket: bucketSelectors.isRequestingBucket(state),
  s3Url: bucketSelectors.getS3Url(state),
  uploadOptions: bucketSelectors.getS3UploadOptions(state),
  userFolderInfo: userSelectors.getFolderInfo(state)
});

const mapDispatchToProps = (dispatch) => ({
  listBucket: () => dispatch(bucketActions.listBucketItems()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ISPDropZone);
