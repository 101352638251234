import "./Bucket.css";

import BucketItem from "./BucketItem";

const Bucket = (props) => {
  const buildBucketItems = () => {
    const { items } = props;
    // According to DO Spaces doc, object size is reported in bytes
    // https://developers.digitalocean.com/documentation/spaces/#list-bucket-contents
    const gigabytes = (items.reduce((accum, val) => accum + val.size, 0) / 1024 ** 3).toFixed(2);
    return {
      items: items.map((item, i) => <BucketItem key={i} item={item} />),
      size: gigabytes,
    };
  };
  const bucket = buildBucketItems();

  return (
    <>
      <div className="bucket-size" style={{ color: "#FD581F" }}>
        Espaço utilizado nesta pasta: {bucket.size}Gb
      </div>
      <div className="bucket">{bucket.items}</div>
    </>
  );
};

export default Bucket;
