export const ROUTES = {
  HOME: '/',
  DROPZONE: '/dropzone',
  LOGIN: '/login',
}

export const ROUTES_PATH = {
  HOME: ROUTES.HOME,
  DROPZONE: `${ROUTES.DROPZONE}(/*)`,
  LOGIN: ROUTES.LOGIN,
}
