import React from 'react';
import thunk from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux';
import { Redirect, Switch, useLocation } from 'react-router-dom'
import { Provider } from 'react-redux'

import reducers from './reducers'
import AuthRoute from './components/AuthRoute'

import LoginPage from './pages/Login'
import ISPDropZone from './pages/ISPDropZone'

import { actionCreators as routeActions } from './domains/route'
import { ROUTES_PATH } from './domains/route/constants'
import { actionCreators as userActions } from './domains/user'

import { config as dotevnConfig } from 'dotenv';

import './App.css'

dotevnConfig()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(reducers, composeEnhancers(
    applyMiddleware(thunk)
))

export default function App() {
  const location = useLocation()
  
  React.useEffect(() => {
    store.dispatch(userActions.getUserFolderSize())
  }, []);

  React.useEffect(() => {
    store.dispatch(routeActions.reactToLocation())
  }, [location]);

  return (
    <Provider store={store}>
      <Switch>
        <AuthRoute path={ROUTES_PATH.DROPZONE}>
          <ISPDropZone />
        </AuthRoute>
        <AuthRoute path={ROUTES_PATH.LOGIN}>
          <LoginPage />
        </AuthRoute>
        <AuthRoute path={ROUTES_PATH.HOME} />
        <Redirect to={ROUTES_PATH.DROPZONE} />
      </Switch>
    </Provider>
  )
}