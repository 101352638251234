/* eslint-disable import/no-anonymous-default-export */

const prod = {
  serverUrl: "https://www.ispdrive.com.br/api",
  baseS3Url: "https://ispdrive.nyc3.digitaloceanspaces.com",
  s3RootBucket: "ispdrive",
};

// const dev = {
//   serverUrl: "http://localhost:3001",
//   baseS3Url: "https://ispdrive.nyc3.digitaloceanspaces.com",
//   s3RootBucket: "ispdrive",
// };

// const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

export default {
  ...prod,
};
