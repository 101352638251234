import { DOMAIN } from './constants'

const domainState = (state) => state[DOMAIN]

export const getToken = (state) =>
  domainState(state).token

export const getUserInfo = (state) =>
  domainState(state).userInfo

export const getUserHomeDir = (state) =>
  getUserInfo(state).homeDir

export const getIsInvalidPassword = (state) =>
  domainState(state).isInvalidPassword

export const getProviderInfo = (state) =>
  getUserInfo(state).providerInfo

export const getFolderInfo = (state) => {
  const { size, limit, usage } = domainState(state)
  return { size, limit, usage }
}
