import { DOMAIN } from './constants'

import { selectors as userSelectors } from '../user'
import { selectors as routeSelectors } from '../route'

import config from '../../config'

const domainState = (state) => state[DOMAIN]

export const isRequestingBucket = (state) =>
  domainState(state).requestingBucket

export const getBucketItems = (state) =>
  domainState(state).items || []
  
export const getS3Url = (state) =>
  `${config.baseS3Url}/${getRawS3Url(state)}`

export const getRawS3Url = (state) =>
  domainState(state).s3Url
  
export const getS3UploadOptions = (state) => {
  const server = config.serverUrl
  const bucket = `${config.s3RootBucket}/${domainState(state).s3Url}`
  const signingUrlHeaders = { 'Authorization': `Bearer ${userSelectors.getToken(state)}` }
  return { server, signingUrlHeaders, signingUrlQueryParams: { bucket } }
}

export const buildS3Url = (state) => {
  const prefix = routeSelectors.getDropZonePrefix()
  const homeDir = userSelectors.getUserHomeDir(state)
  return `${homeDir}${prefix}`
}

export const getAddingFolderError = (state) =>
  domainState(state).addingFolderError

export const isAddingFolder = (state) =>
  domainState(state).isAddingFolder

export const isNewFolderModalOpen = (state) =>
  domainState(state).isNewFolderModalOpen