import "./BucketItem.css";
import { Fragment, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExcel,
  faFileImage,
  faFolder,
  faCompress,
  faFileAudio,
  faFileVideo,
  faFile,
  faFileWord,
  faFilePowerpoint,
  faFileCsv,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";

import { selectors as userSelectors } from "../domains/user";
import { actionCreators as bucketActions } from "../domains/bucket";

import { ROUTES } from "../domains/route/constants";
import DialogConfirm from "./DialogConfirm";

import config from "../config";

const extensionMap = {
  default: faFile,
  folder: faFolder,
  jpg: faFileImage,
  jpeg: faFileImage,
  xls: faFileExcel,
  xslx: faFileExcel,
  zip: faCompress,
  rar: faCompress,
  mp3: faFileAudio,
  mp4: faFileVideo,
  txt: faFile,
  csv: faFileCsv,
  doc: faFileWord,
  docx: faFileWord,
  pdf: faFilePdf,
  ppt: faFilePowerpoint,
  pptx: faFilePowerpoint,
};

const offsetName = (item) => {
  if (!item || !item.key) return "";
  if (item.key.endsWith("/")) {
    item.key = item.key.substr(0, item.key.length - 1);
  }
  return item.key.substr(item.key.lastIndexOf("/") + 1, item.key.length);
};

const extensionDescriptor = (filename) => {
  if (!filename.includes(".")) return "folder";
  return filename.substr(filename.lastIndexOf(".") + 1);
};

const BucketItem = (props) => {
  const [deleteRequested, setRequestDelete] = useState(false)
  
  const requestDelete = () => {
    setRequestDelete(true)
  }

  const cancelDelete = () => {
    setRequestDelete(false)
  }

  const submitDelete = (item) => {
    props.delete(item)
  }

  const handleFileClick = async (e) => {
    e.stopPropagation()
    const key = e.currentTarget.getAttribute('item')
    const fname = e.currentTarget.getAttribute('fname')
    const { data: url } = await axios.get(`${config.serverUrl}/bucket/get?prefix=${key}&token=${props.token}`)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fname)
    link.setAttribute('target', '_blank')
    link.click()
  }

  const { key, type } = props.item;
  const fname = offsetName(props.item);
  const extensionType = extensionDescriptor(key)
  const iconType = extensionMap[extensionType] || extensionMap['default']

  return (
    <Fragment>
      <div className="item-section">
        <DialogConfirm
          isOpen={deleteRequested}
          title="Excluir"
          message="Deseja realmente excluir?"
          onConfirm={() => { submitDelete(props.item) }}
          onCancel={cancelDelete}
        />
         <div className="bucket-item">
          <FontAwesomeIcon icon={iconType} />
          {type==='file' && (
            <div className="bucket-item-lnk" onClick={handleFileClick} item={key} fname={fname}>
              <div className="bucket-item-name">{fname}</div>
            </div>
          )}
          {type==='dir' && (
            <Link to={`${ROUTES.DROPZONE}${key.replace(props.homeDir, "")}`}>
              <div className="bucket-item-name">{fname}</div>
            </Link>
          )}
        </div>
        <div onClick={requestDelete} className="delete-item">
          <span className="material-icons">delete</span>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  token: userSelectors.getToken(state),
  homeDir: userSelectors.getUserHomeDir(state),
})

const mapDispatchToProps = (dispatch) => ({
  delete: (item) => dispatch(bucketActions.deleteItem(item))
})

export default connect(mapStateToProps, mapDispatchToProps)(BucketItem);
