import { DOMAIN } from './constants'

import { selectors as bucketSelectors } from '../bucket'
import { selectors as userSelectors } from '../user'

const domainState = (state) => state[DOMAIN]

export const getInvalidPassword = (state) =>
  domainState(state).invalidPassword

export const getBreadCrumbs = (state) => {
  const currentLocation = bucketSelectors.getRawS3Url(state)
  const homeDir = userSelectors.getUserHomeDir(state)
  if (!currentLocation || currentLocation === homeDir) return [{ path: '', name: 'home' }]
  const crumbs = currentLocation.replace(`${homeDir}/`, '').split('/').map(decodeURI)
  return ['home', ...crumbs].reduce((acc, crumb) => {
    if (acc.length === 0) return [{ path: '', name: 'home' }]
    if (crumb === "") return [...acc]
    return [...acc, {path: `${acc[acc.length-1].path}/${crumb}`, name: crumb }]
  }, [])
}